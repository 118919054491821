exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-gastronomie-js": () => import("./../../../src/pages/gastronomie.js" /* webpackChunkName: "component---src-pages-gastronomie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-mannschaften-js": () => import("./../../../src/pages/mannschaften.js" /* webpackChunkName: "component---src-pages-mannschaften-js" */),
  "component---src-pages-sonstiges-js": () => import("./../../../src/pages/sonstiges.js" /* webpackChunkName: "component---src-pages-sonstiges-js" */),
  "component---src-pages-training-js": () => import("./../../../src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */),
  "component---src-pages-veranstaltungen-js": () => import("./../../../src/pages/veranstaltungen.js" /* webpackChunkName: "component---src-pages-veranstaltungen-js" */),
  "component---src-pages-verein-js": () => import("./../../../src/pages/verein.js" /* webpackChunkName: "component---src-pages-verein-js" */),
  "component---src-pages-vorstand-js": () => import("./../../../src/pages/vorstand.js" /* webpackChunkName: "component---src-pages-vorstand-js" */),
  "component---src-templates-default-js": () => import("./../../../src/templates/default.js" /* webpackChunkName: "component---src-templates-default-js" */),
  "component---src-templates-news-list-js": () => import("./../../../src/templates/news-list.js" /* webpackChunkName: "component---src-templates-news-list-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

